$(function () {
    $(document).on('click','.reply-button', function(){
      $('textarea.nested-comment-input').val('');
      $(this).parent().children('div.reply-form').toggle();
    });

    $(document).on('click', '.resolve-button', function() {
      let comment = $(this).closest('.media');
      let commentId = comment.attr('data-commentid');

      let self = this;

      let url = `/comments/resolve/${commentId}`;

      console.log(url);

      $.ajax({
        type: 'POST',
        url: url,
        success: function(data) {
          let popover = $(self).closest(".popover");

          let commentData = data.comment;
          let numberOfCommentsRemoved = data.number_of_replies_removed;
          let key = commentData.key;
          let popoverBtn = $(`[data-key=${key}]`);
          let commentCountElement = popoverBtn.children(".comment-count");

          let commentCount = parseInt(commentCountElement.attr('data-count'));

          commentCountElement.attr('data-count', commentCount - numberOfCommentsRemoved); 

          comment.remove();

          // TODO: move to resolved

          let popoverContent = popover.children(".popover-body");
          let html = popoverContent.html();
          popoverBtn.attr('data-content', html);
        },
        error: function(jqXhr, status, errorMessage) {
          let error = jqXhr.responseJSON;
          if (error == undefined) {
            $('div.error-alert').show();
            $('div.error-alert').text('Something went wrong.');
          } else {
            $('div.error-alert').show();
            $('div.error-alert').text(error.error);
          }
        }
      });
    });

    $(document).on('click','.delete-button', function(){
      
      let comment = $(this).closest('.media');
      let comment_id = comment.attr('data-commentid');

      let self = this;

      let delete_comment_url  = '/comments/' + comment_id;
      $.ajax({
        type: 'DELETE',
        url: delete_comment_url,
				data: 'comment',

				success: function(data) {
          let popover = $(self).closest(".popover");

          let commentData = data.comment;
          let numberOfCommentsRemoved = data.number_of_replies_removed;
          let key = commentData.key;
          let popoverBtn = $(`[data-key=${key}]`);
          let commentCountElement = popoverBtn.children(".comment-count");

          let commentCount = parseInt(commentCountElement.attr('data-count'));

          commentCountElement.attr('data-count', commentCount - numberOfCommentsRemoved); 

          comment.remove();

          let popoverContent = popover.children(".popover-body");
          let html = popoverContent.html();
          popoverBtn.attr('data-content', html);          
				},
				error: function(jqXhr, status, errorMessage) {
          let error = jqXhr.responseJSON;
          $('div.error-alert').show();
          $('div.error-alert').text(error.error);
				}
      });
    });

    $(document).on('click','.submit-comment', function(){
      comment = $('form.comment-form').serializeArray()
      let form = $('form.comment-form');
      let comment = getFormData(form);
   
      let privateComment = $(this).data('private');

      let create_comment_url = '';

      if (privateComment) {
        create_comment_url = '/comments/private';
      } else {
        create_comment_url = '/comments';
      }

      let self = this;

      $.ajax({
        type: 'POST',
        url: create_comment_url,
				data: comment,

				success: function(data) {
					if (data.success) {
            let commentHtml = appendNewCommentHTML(data, privateComment);

            let div = document.createElement('div');
            div.innerHTML = commentHtml;

            $(commentHtml).insertBefore('.comment-form');
            $('div.error-alert').hide();
            $('textarea.comment-input').val('');

            let popover = $(self).closest(".popover");
            let popoverContent = popover.children(".popover-body");
            let html = popoverContent.html();
            let key = comment['comment[key]'];
            let popoverBtn = $(`[data-key=${key}]`);
            let commentCountElement = popoverBtn.children(".comment-count");

            let commentCount = parseInt(commentCountElement.attr('data-count'));

            popoverBtn.attr('data-content', html);

            commentCountElement.attr('data-count', commentCount + 1);      
					}else{
            $('div.error-alert').show();
            $('div.error-alert').text(data.error);
          }
				},
				error: function(jqXhr, status, error) {
          let response = jqXhr.responseJSON;
          $('div.error-alert').show();
          $('div.error-alert').text(response.error);          
				}
			});
    })

    $(document).on('click','.submit-nested-comment', function(){

      var form = $(this).parent().children('form.nested-comment-form');
 
      var comment = getFormData(form);

      let privateComment = $(this).data('private');

      let create_comment_url = '';

      if (privateComment) {
        create_comment_url = '/comments/private';
      } else {
        create_comment_url = '/comments';
      }

      $.ajax({
        type: 'POST',
        url: create_comment_url,
        data: comment,
        context: this,

				success: function(data) {

					if (data.success) {
            $(nestedComment(data)).insertAfter($(this).parent());
            $(this).parent().children('div.nested-error-alert').hide();
            $(this).parent().toggle();

            let popover = $(this).closest(".popover");
            let popoverContent = popover.children(".popover-body");
            let html = popoverContent.html();
            let key = comment['comment[key]'];
            let popoverBtn = $(`[data-key=${key}]`);
            let commentCountElement = popoverBtn.children(".comment-count");

            let commentCount = parseInt(commentCountElement.attr('data-count'));

            popoverBtn.attr('data-content', html);

            commentCountElement.attr('data-count', commentCount + 1);
          }else{
            $('div.nested-error-alert').show();
            $('div.nested-error-alert').text(data.error);
          }
				},
				error: function(jqXhr, status, error) {
          let response = jqXhr.responseJSON;
          $('div.nested-error-alert').show();
          $('div.nested-error-alert').text(response.error) ;         
				}
			});
    })


    // Hide popover on click outside
    $('body').on('click', function (e) {
      $('[data-toggle=popover]').each(function () {
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
          }
     
          $(document).on('click','.close-popover',function(){
            $('#comment-popover').popover('hide');
          });

      });

    });
      
      $(document).keydown(function(e){
        if (e.keyCode === 27) {
          $('[data-toggle=popover]').each(function () {
            $(this).popover('hide');
          })
        }
      });

    $(document).on('click','.close-popover',function(){
      $('#comment-popover').popover('hide');
      $('[data-toggle=popover]').each(function () {
        $(this).popover('hide');
      })
    });

    $('[data-toggle="popover"]').on('shown.bs.popover', function() {
      $('.popover').find(".comment-input").focus().select();
      // show popover
    });

  })


function getFormData($form){
  var unindexed_array = $form.serializeArray();
  var indexed_array = {};

  $.map(unindexed_array, function(n, i){
      indexed_array[n['name']] = n['value'];
  });

  return indexed_array;
}


function appendNewCommentHTML(data, privateComment){

  var comment = data.comment;
  console.log(comment);
  var user = data.user;
  
  var htmlCode = 
  `
  <div class="media mt-3" data-commentid="${comment.id}">
  <h2 class="mr-3"><span class="badge badge-secondary"> ${user.watiam.charAt(0)} </span> </h2>
    <div class="media-body">
    <span class="mt-0 comment-title">${user.watiam}</span>
    
    <span class="comment-time"> ${getDateInString(comment.created_at)}
      ${privateComment ? "<span class='badge badge-secondary'>Private</span>" : ""}
    </span>
    <div class="comment-text">
      <p>
        ${comment.comment.replace("'", "")}
      </p>
    </div>

      <span class="reply-button"><a class="comment-class"> Reply</a></span>
      <span class="delete-button"><a class="comment-class">Delete</a></span>


      <div class="reply-form" style="display:none">
        <form action="/comments" method="post" accept-charset="UTF-8" class="nested-comment-form">
        <input name="comment[user_id]" value="${user.id}" type="hidden">
        <input name="comment[key]" value="${comment.key}" type="hidden">
        <input name="comment[resolved]" value="1" type="hidden">
        <input name="comment[event_action_plan_submission_id]" value="${comment.event_action_plan_submission_id}" type="hidden">
        <input name="comment[parent_comment_id]" value="${comment.id}" type="hidden">
        <textarea name="comment[comment]" class="nested-comment-input"></textarea>
        </form> 
        <button type="submit"class="btnNext btn btn-sm btn-primary submit-nested-comment" tabindex="1" data-private="${privateComment}"> Add comment </button>
      </div> 

      </div>
  </div>
`

  return htmlCode;
}


function nestedComment(data) {
  var parent_comment = data.comment;

  var user = data.user;
  var htmlCode = `
  <div class="media mt-1" data-commentid="${parent_comment.id}">
  <h2 class="pr-3"><span class="badge badge-secondary">  ${user.watiam.charAt(0)} </span> </h2>
  <div class="media-body">
    <span class="mt-0 comment-title"> ${user.watiam} </span>
    <span class="comment-time">${getDateInString(parent_comment.created_at)}</span>
    <div class="comment-text"> ${parent_comment.comment.replace("'", "")}   </div>
    <span class="delete-button"><a class="comment-class">Delete</a></span>
  </div>
</div>
  `;
  return htmlCode;
}

function getDateInString(date){
  var date = new Date(Date.parse(date));
  var monthName = getMonth(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var date_string = date.toDateString().slice(7);
  return monthName + " " + date_string + " " + hours + ":" + minutes;
}

function getMonth(date){
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
  return monthNames[date.getMonth()];
}