$(function() {
    $('#event_action_plan_app .btnNext').click(function(){
        $('#formTabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
    });

    $('#event_action_plan_app .btnPrevious').click(function(){
        $('#formTabs > .nav-item > .active').parent().prev('li').find('a').trigger('click');
    });

    $('#event_action_plan_app a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
       return false;
    });

    $('.offcampus-control').change(function(e) {
        const value = $(this).val();

        const transportation_details = $('#off_campus_transporation_section');

        if (value == 'true') {
            transportation_details.removeClass('hide');
        } else {
            transportation_details.addClass('hide');
        }
    });
    
    $('.outside-control').change(function(e) {
        const contingency_logistics = $('#contingency_logistics');
        const value = $(this).val();

        if (value == 'yes_all_outside' || value == 'yes_some_outside') {
            contingency_logistics.removeClass('hide');
        } else {
            contingency_logistics.addClass('hide');
        }
    });

    $('.action_plan_items').on('change', '.action_plan_item_status',  function(e) {
        const status = $(this).val();

        console.log(status);
        const cost_field = $(this).parent().parent().parent().find('.anticipated_cost_container');

        if (status == "to_purchase") {
          cost_field.removeClass('hide');
        } else {
          cost_field.addClass('hide');
        }
    });


    $('#event_action_plan_app').on('cocoon:after-insert', function() {
        document.querySelectorAll('.datepicker').flatpickr({
            dateFormat: "Y-m-d"
        });
    });
});